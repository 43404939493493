<template>
  <section class="choose-randomCandidate-distribute-component" v-auth="'distribute,remove'">
    <el-main>
      <div class="header">
        <div class="title">分配列表</div>
      </div>
      <el-table :data="table.data" default-expand-all row-key="id" v-loading="table.loading">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="org_name" label="单位名称"></el-table-column>
        <el-table-column prop="parent_org_name" label="所属司法局"></el-table-column>
        <el-table-column prop="czjm" label="已分配常住居民数">
          <template slot-scope="scope">
            <span class="blue" @click="peoplelist(scope.row)">{{scope.row.czjm}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              @click="changeComponent('toDistribute',scope.row)"
              type="text"
              size="small"
              v-if="auth.distribute"
            >分配</el-button>
            <el-button @click="changeComponent('remove',scope.row)" type="text" size="small" v-if="auth.remove">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <component :is="componentName" @getList="getList" ref="component" v-if="show" @changeShow="changeShow"></component>
    <peoplelist ref="peoplelist"></peoplelist>
  </section>
</template>

<script>
import remove from './components/remove';
import toDistribute from './components/distribute';
import peoplelist from './components/peoplelist';
import Candidate from '@/api/choose/candidate';
import Public from '@/api/public';
export default {
  name: 'distribute',
  props: {},
  components: { remove, toDistribute, peoplelist },
  data() {
    return {
      show: false,
      componentName: '',
      table: {
        loading: false,
        data: [],
      },
      auth: {
        distribute: true,
        remove: true,
      },
    };
  },
  methods: {
    peoplelist(data) {
      this.$refs.peoplelist.toggle(true, { fpdw: data.org_id });
    },
    changeShow(show) {
      this.show = show;
    },
    changeComponent(name, data) {
      this.changeShow(true);
      this.componentName = name;
      this.$nextTick(() => {
        this.$refs.component.toggle(true, data);
      });
    },
    getList() {
      this.table.loading = true;
      Candidate.distributeList()
        .then((res) => {
          this.table.data = res;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    Public.components({
      OrgGradeTree: 0,
    }).then((res) => {
      console.log(11111111);
      console.log(res.OrgGradeTree);
      console.log(111111);
    });
    this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.choose-randomCandidate-distribute-component {
  background-color: #fff;
  height: 100%;
  .header {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      padding: 15px 0;
      font-weight: bold;
    }
  }
  .el-main {
    .el-table {
      border: 1px solid rgba(232, 232, 232, 1);
      border-bottom: none;
    }
  }
}
</style>
