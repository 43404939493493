<template>
  <el-dialog
    append-to-body
    title="分配"
    width="80%"
    :visible.sync="show"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="choose-selectedPlan-checkCode"
    :before-close="toggle"
  >
    <el-table
      ref="table"
      v-loading="table.loading"
      :data="table.data"
      default-expand-all
      row-key="id"
      height="90%"
      :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
      :cell-style="{'text-align':'center'}"
      @selection-change="handleSelectionChange"
      @cell-click="selectOrCancle"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
      <el-table-column prop="xm" label="姓名"></el-table-column>
      <el-table-column prop="xb" label="性别" width="50"></el-table-column>
      <el-table-column prop="lxfs" label="联系方式"></el-table-column>
      <el-table-column prop="cjzd" label="常住地址"></el-table-column>
      <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="table.query.page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="table.query.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="table.total"
    ></el-pagination>
    <div class="footer">
      <div class="search">
        <el-input v-model="table.query.keyword" placeholder="请输入关键词" size="small"></el-input>
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </div>
      <div class="distribute">
        <el-button type="primary" size="small" @click="distributeLot">批量分配</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Candidate from '@/api/choose/candidate';
export default {
  // 分配弹窗
  name: 'distribute',
  props: {},
  components: {},
  data() {
    return {
      show: false,
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
          fpdw: 'n',
        },
        loading: false,
        total: 0,
      },
      data: '',
      select: [],
    };
  },
  methods: {
    indexMath(index) {
      return index + (this.table.query.page - 1) * this.table.query.size + 1;
    },
    // 切换单元格的选中状态
    selectOrCancle(data) {
      this.$refs.table.toggleRowSelection(data);
    },
    // 搜索
    search() {
      return this.getList();
    },
    // 批量分配
    distribute(data) {
      this.table.loading = true;
      Candidate.distribute({
        fpdw: this.data.org_id,
        sfzhm: [data.sfzhm],
      }).then(() => {
        this.$message.success('分配成功');
        this.getList();
        return this.$emit('getList');
      });
    },
    distributeLot() {
      if (this.select.length === 0) {
        this.$alert('请选择要分配的候选人');
        return;
      }
      let sfzhm = this.select.map((item) => {
        return item.sfzhm;
      });
      this.table.loading = true;
      Candidate.distribute({
        fpdw: this.data.org_id,
        sfzhm: sfzhm,
      }).then(() => {
        this.$message.success('分配成功');
        this.getList();
        return this.$emit('getList');
      });
    },
    // 选中框变化时
    handleSelectionChange(data) {
      this.select = data;
    },
    toggle(show = false, data) {
      if (show instanceof Function) {
        show();
        this.$emit('changeShow', false);
        return;
      }
      this.show = show;
      this.data = data;
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query = {
        page: 1,
        size: 100,
        keyword: '',
      };
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      this.select = [];
      Candidate.list(this.table.query)
        .then((res) => {
          console.log(res);
          this.table.data = res.items;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-pagination {
  padding-top: 10px;
  box-sizing: border-box;
}
.footer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  .search {
    flex: 1;
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 10px;
    }
  }
  .distribute {
    margin-left: 100px;
    display: flex;
    align-items: center;
  }
}
::v-deep .el-dialog {
  margin: 5vh auto 0 auto !important;
}
::v-deep .el-dialog__body {
  height: 80vh;
}
</style>
